<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        {{ $t('subscription.pricing_plans') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t('subscription.all_basic_functionality_is_available_FREE_of_charge_for_customers') }}
      </p>

    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <h3 class="my-5 text-center text-primary">
      {{ $t('subscription.for_customers') }}
    </h3>
    <subscription-list target="customer"></subscription-list>
    <!--/ pricing plan cards -->
    <h3 class="my-5 text-center text-warning" >
      {{ $t('subscription.for_contractors') }}
    </h3>
    <subscription-list target="contractor"></subscription-list>
    <!-- pricing free trial -->
    <div class="pricing-free-trial" v-if="plan==='customer'">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t('Still undecided?') }} {{ $t('Just try it!') }}
              </h3>
              <h5>{{ $t('Go to the list of contractors available in your area and select a contractor yourself') }}</h5>
<!--              <a-->
<!--                v-scroll-to="{-->
<!--                  el: '#quote',-->
<!--                  offset: -100,-->
<!--                }"-->
<!--                href="#"-->
<!--              >-->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mt-lg-3"
                  @click="$router.push({name:'apps-service-offer-list'})"
                >
                  {{ $t('View list of service providers') }}
                </b-button>
<!--              </a>-->

            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BCardBody, BAvatar, BCardFooter
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  currency,
} from '@/mixins/options'
import ScrollTo from 'vue-scrollto'
import store from '@/store'
import landingStoreModule from '@/views/pages/landing/landingStoreModule'
import SubscriptionList from '@/views/apps/subscription/subscriptionList.vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BCardBody,
    BAvatar,
    BCardFooter,
    SubscriptionList
  },
  directives: {
    Ripple,
    ScrollTo,
  },
  props: {
    plan: {
      type: String,
      required: false,
      default: () => 'customer',
    },

  },
  data() {
    return {
      mainColor: 'primary',
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      plansQuantity: null,
    }
  },

  watch: {
    '$store.state.appLanding.userRole': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.toggleUserPlan(newVal)
        }
      },
    },

  },
  created() {
    this.toggleUserPlan(this.plan)
  },
  methods: {

    resolveButtonVariant(plan) {
      return plan.popular ? this.mainColor : `outline-${this.mainColor}`
    },
    // toggleMonthlyPlan() {
    //   this.monthlyPlanShow = this.status === 'monthly'
    // },
    toggleUserPlan(user) {

      if('contractor' in this.$route.query){
        store.state.appLanding.userRole = 'contractor'
      }

      if (user) {
        store.state.appLanding.userRole = user
      }

      const plans = this.pricingPlans.find(value => value[store.state.appLanding.userRole])
      this.pricing = plans[store.state.appLanding.userRole]
      this.plansQuantity = Object.keys(this.pricing).length
      this.mainColor = store.state.appLanding.userRole === 'customer' ? 'primary' : 'warning'
    },
  },
  setup() {
    const LANDING_APP_STORE_MODULE_NAME = 'appLanding'

    // Register module
    if (!store.hasModule(LANDING_APP_STORE_MODULE_NAME)) store.registerModule(LANDING_APP_STORE_MODULE_NAME, landingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LANDING_APP_STORE_MODULE_NAME)) store.unregisterModule(LANDING_APP_STORE_MODULE_NAME)
    })
    const pricingPlans = ref([])
    pricingPlans.value = [
      {
        customer: {
          basicPlan: {
            title: 'subscription.user',
            popular:false,
            img: require('@/assets/images/svg/user.svg'),
            subtitle: 'subscription.a_simple_start_for_everyone',
            monthlyPrice: 0,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'no'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:2},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'no'},
              {name:'common.personal_manager', value:'no'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: '',
          },
          standardPlan: {
            title: 'subscription.landlord',
            popular:true,
            img: require('@/assets/images/svg/landlord.svg'),
            subtitle: 'subscription.for_property_owners',
            monthlyPrice: 29,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'no'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:10},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'no'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: 'subscription.popular',
          },
          enterprisePlan: {
            title: 'subscription.agency',
            popular:false,
            img: require('@/assets/images/svg/agency.svg'),
            subtitle: 'subscription.for_small_and_medium_sized_businesses',
            monthlyPrice: 99,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'no'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:30},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'no'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
            // 'Search for contractors and provide services at the same time',
            // 'Unlimited amount of proposals on services requests',
            // 'Unlimited number of requests for services',
            // 'Unlimited number of service offers',
            // 'Calendar of events',
            ]
          },
        },
      },
      {
        contractor: {
          basicPlan: {
            title: 'subscription.freelancer',
            popular:false,
            img: require('@/assets/images/svg/freelancer.svg'),
            subtitle: 'subscription.a_simple_start_for_everyone',
            monthlyPrice: 10,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'yes'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:2},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'yes'},
              {name:'common.personal_manager', value:'no'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: 'subscription.first_month_free_of_charge',
          },
          standardPlan: {
            title: 'subscription.professional',
            popular:true,
            img: require('@/assets/images/svg/professional.svg'),
            subtitle: 'subscription.for_self_employed_contractors',
            monthlyPrice: 49,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'yes'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:10},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'yes'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'no'},
              {name:'Dashboards', value:'no'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: 'subscription.popular',
          },
          enterprisePlan: {
            title: 'subscription.enterprise',
            popular:false,
            img: require('@/assets/images/svg/enterprise.svg'),
            subtitle: 'subscription.for_small_and_medium_sized_businesses',
            monthlyPrice: 299,
            yearlyPlan: {
              perMonth: 0,
              totalAnnual: 0,
            },
            planFeatures: [
              {name:'Service Requests',value:'subscription.unlimited'},
              {name:'Service Offers', value:'yes'},
              // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
              {name:'location.locations', qtyMax:10},
              {name:'Calendar of events', value:'yes'},
              {name:'point.service_finder', value:'yes'},
              {name:'point.request_finder', value:'yes'},
              {name:'common.personal_manager', value:'yes'},
              {name:'referral.referrals', value:'yes'},
              {name:'Dashboards', value:'yes'},
              // 'Search for contractors and provide services at the same time',
              // 'Unlimited amount of proposals on services requests',
              // 'Unlimited number of requests for services',
              // 'Unlimited number of service offers',
              // 'Calendar of events',
            ],
            badge: '',
          },
        },
      },
    ]
    const { getCurrencySymbol } = currency()

    const getLink = (plan)=>{
      const tel = encodeURIComponent('+').concat('34603334187')
      const link = 'https://wa.me/'.concat(tel)
      const url = new URL(link)
      url.searchParams.append('text', plan)
      return url.href
    }

    return {
      pricingPlans,
      getCurrencySymbol,
      getLink,
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-pricing.scss';
.pricing-card .card-body>img{
  height: 96px!important;
}
.card .card-footer{
  border-top: none!important;
}
.dark-layout .pricing-free-trial {
  background-color: #283046;
}
.pricing-badge{
  min-height: 4rem;
}
</style>
